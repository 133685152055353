import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useFetchProductDataBySkuMutation,
  useGetProductDataBySkuQuery,
  useGetProductDataQuery,
} from "../../features/product/productSlice";
import { getUser } from "../../home/Home";
import "./ProductPage.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import { setOrder, setVarient } from "../../features/order/orderSlice";
import { Bounce, toast } from "react-toastify";
import { InquiryFlow } from "../InquiryFlow/InquiryFlow";
function ProductPage(props) {
  //login
  const [user, setUser] = useState(getUser());
  const navigate = useNavigate();
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user]);
  //login end
  const { id } = useParams();
  const location = useLocation();

  const [storeType, setStoreType] = useState(location.pathname.split("/")[2]);
  const [productData, setProductData] = useState(null);
  console.log("productData", productData);
  const {
    data: virtualProductData,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetProductDataQuery({ id: id });
  if (isError && error.status === 401) {
    navigate("/login");
  }
  useEffect(() => {
    if (storeType === "virtual") {
      console.log("virtual", virtualProductData?.product.sku);
      setProductData(virtualProductData);
    }
  }, [virtualProductData]);

  const isCombination = productData?.product?.isCombination || false;

  const [skip, setSkip] = useState(true);
  const {
    data: retailerProductData,
    isLoading: retailerProductLoading,
    isSuccess: retailerProductSuccess,
    isError: retailerProductError,
    error: retailerProductErrorData,
  } = useGetProductDataBySkuQuery({ sku: id, bearerToken: user });

  useEffect(() => {
    console.log("instore", retailerProductData?.data?.product?.sku);
    if (storeType === "instore") {
      setProductData(retailerProductData?.data);
    }
  }, [retailerProductData]);

  // console.log("retailer",retailerProductData?.data?.product.sku)

  // const [fetchProductDataBySku, { data, error: fetchProductDataBySkuError }] =
  // useFetchProductDataBySkuMutation();

  // useEffect(() => {
  //   if(virtualProductData?.product?.sku && storeType === "instore"){
  //     fetchProductDataBySku({sku: virtualProductData?.product.sku, bearerToken: user}).then((res) => {
  //       console.log(res)
  //       console.log(res.data.data);
  //       setProductData(res.data.data);
  //     }
  //     );
  //   }
  // }
  // ,[virtualProductData]);

  // useEffect(() => {
  //   // if error then toast error
  //   if(retailerProductError){
  //     console.log(retailerProductErrorData?.data?.message);
  //   }
  // }, [retailerProductError]);

  // useEffect(() => {
  //   if(virtualProductData?.product?.sku && storeType === "instore"){
  //     setSkip(false);
  //     if(retailerProductData){
  //     setSkip(true);
  //     setProductData(retailerProductData.data);
  //     }
  //   }
  // }, [virtualProductData, retailerProductData]);

  // attributes from productData
  const attributes = productData?.product?.attributes?.[0] || null;
  const options = productData?.product?.options?.[0] || null;
  const variants = productData?.product?.variants;
  const [selectedVariant, setSelectedVariant] = useState({});
  let sliderRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState([]);
  const handleOptionChange = (option) => {
    // find the optionName in selectedOption and update the value
    const optionName = option.optionName;
    const optionValue = option.optionValue;
    const newSelectedOption = selectedOption.map((option) => {
      if (Object.keys(option)[0] === optionName) {
        return {
          [optionName]: optionValue,
        };
      }
      return option;
    });
    setSelectedOption(newSelectedOption);
    sliderRef.slickGoTo(0);
  };

  // make an array with the number of options with key id pairs
  const optionArray = [];
  useEffect(() => {
    if (options) {
      Object.keys(options).map((key) => {
        const option = {
          [key]: options[key][0].optionValue,
        };
        optionArray.push(option);
      });
      setSelectedOption(optionArray);
    }
  }, [options]);

  // find the variant with the selected options
  // useEffect(() => {
  //   if(variants){
  //     variants.map((variant) => {
  //       // check if the variant options are equal to selected options both have same structure
  //       const isMatch = variant.options.every((option) => {
  //         console.log(option, selectedOption)
  //         return selectedOption.find((selectedOption) => {
  //           return option[Object.keys(option)[0]] === selectedOption[Object.keys(selectedOption)[0]];
  //         });
  //       });
  //       if(isMatch){
  //         console.log(isMatch)
  //         console.log(variant)
  //         console.log(selectedOption)
  //         setSelectedVariant(variant);
  //       }else{
  //         setSelectedVariant(null);
  //       }

  //     });
  //   }
  // }, [selectedOption]);

  useEffect(() => {
    if (variants) {
      let foundVariant = null;
      for (const variant of variants) {
        const isMatch = variant.options.every((option) => {
          return selectedOption.some((selected) => {
            return (
              option[Object.keys(option)[0]] ===
              selected[Object.keys(selected)[0]]
            );
          });
        });
        if (isMatch) {
          foundVariant = variant;
          break; // Exit the loop if a match is found
        }
      }
      setSelectedVariant(foundVariant);
    } else {
      setSelectedVariant(null);
    }
  }, [selectedOption, variants]);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // vertical: true,
    verticalSwiping: true,
  };
  // const colors = ["#2B6192", "#14474A", "#946624"];
  const [colors, setColors] = useState([]);
  const [colorOption, setColorOption] = useState([]);
  const [clusteredProducts, setClusteredProducts] = useState([]);
  useEffect(() => {
    if (productData) {
      // console.log("clusteredProducts" in productData.product)
      if (productData?.product) {
        if ("clusteredProducts" in productData.product) {
          // const tempColors = [];
          // productData.product.clusteredProducts.map((product) => tempColors.push(product.code));
          // setColors(tempColors);
          console.log(productData.product.clusteredProducts);
          setClusteredProducts(productData.product.clusteredProducts);
        }
      }
    }
  }, [productData]);

  const handleColorClick = (color) => {
    setColorOption(color);
  };

  const goBack = () => {
    navigate(-1);
  };
  const dispatch = useDispatch();
  const handleBuyNow = (orderId) => {
    if (isCombination && !selectedVariant) {
      return;
    }
    dispatch(setOrder(orderId));
    dispatch(setVarient(selectedVariant));
    navigate("/orders/new");
  };

  //on click of sku copy to clipboard
  const copyToClipboard = (e, text) => {
    navigator.clipboard.writeText(text);
    e.target.innerText = "Copied!";
    setTimeout(() => {
      e.target.innerText = text;
    }, 1000);
  };

  const [showInquiryPopup, setShowInquiryPopup] = useState(false);
  const [showInquiryTray, setShowInquiryTray] = useState(false);
  const [showProductPage, setShowProductPage] = useState(true);
  const [showInquiryReview, setShowInquiryReview] = useState(false);
  const jeweltryBtnRef = useRef(null);
  //---------------------------------------------------------TryOn
  useEffect(() => {
    unm();
  }, []);

  function unm() {
    const tryOnFrame = document.getElementById("iframe-container");
    //alert(tryOnFrame)
    if (tryOnFrame != null || tryOnFrame != undefined) {
      tryOnFrame.remove();
    }
  }

  useEffect(() => {
    // alert(tryon)
    setTimeout(() => {
      unm();
      try {
        const JeweltryFun = Window.myConstructor;
        const JeweltryConstructor = new JeweltryFun(
          process.env.REACT_APP_TRY_ON_SECRET_KEY,
          productData?.product?.sku
        );
        JeweltryConstructor.init();
      } catch (err) {
        console.log(err);
      }
    }, 1000);
    //  unm()
    return () => {
      unm();
    };
  }, [productData?.product?.sku]);
  //---------------------------------------------------------
  return (
    <>
      <div
        className="productpage"
        style={{ display: showProductPage ? "block" : "none" }}
      >
        <div className="navigation">
          <div
            className="left pointer"
            onClick={() => {
              goBack();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
            >
              <path d="M400-107.692 27.692-480 400-852.308l42.539 42.539L112.769-480l329.77 329.769L400-107.692Z" />
            </svg>
            <div className="label mont-bold">Product Details</div>
          </div>
          {storeType == "virtual" && (
            <div
              className="inquire"
              onClick={() => {
                setShowInquiryReview(true);
              }}
            >
              INQUIRY TRAY
            </div>
          )}
        </div>
        <div className="main">
          <div className="left">
            <Slider
              {...settings}
              ref={(slider) => {
                sliderRef = slider;
              }}
            >
              {/* {isCombination && selectedVariant && <img key={selectedVariant?._id} src={selectedVariant?.image} alt="Product" />} */}
              {storeType === "instore" &&
                productData?.product?.images?.map((image) => {
                  const transformedImage = image.replace(
                    "upload/",
                    "upload/if_w_gt_800/w_800/if_end/"
                  );
                  return (
                    <img
                      key={productData?.product?._id}
                      src={
                        transformedImage ? transformedImage : "/Netre-logo.svg"
                      }
                      alt="Product"
                    />
                  );
                })}
              {storeType === "virtual" &&
                productData?.product?.image?.map((image) => {
                  const transformedImage = image.replace(
                    "upload/",
                    "upload/if_w_gt_800/w_800/if_end/"
                  );
                  return (
                    <img
                      key={productData?.product?._id}
                      src={transformedImage}
                      alt="Product"
                    />
                  );
                })}
              {productData?.product?.images?.length < 1 ||
                (productData?.product?.image?.length < 1 && (
                  <img
                    key={productData?.product?._id}
                    src="/Netre-logo.svg"
                    alt="Product"
                  />
                ))}
              {productData?.product?.url_360 && (
                <iframe
                  id="threeD-view-iframe-container"
                  className="threeD-view-iframe-container"
                  title="3D-view"
                  src={productData?.product?.url_360}
                ></iframe>
              )}
            </Slider>
          </div>
          <div className="right">
            {productData && (
              <div className="text-1">
                {productData?.product?.name
                  ? productData?.product?.name
                  : productData?.product?.title.en}
              </div>
            )}
            <div className="text-2">
              {isCombination ? (
                selectedVariant ? (
                  <>&#8377;&nbsp;{selectedVariant.price}</>
                ) : (
                  <div className="not-available">Currently not available</div>
                )
              ) : (
                <>
                  {/* {productData?.product?.price
                    ? productData?.product?.price
                    : productData?.product?.prices.price} */}

                  {Math.round(productData?.product?.prices?.discount) !== 0 ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          color: "#44444480",
                          textWrap: "nowrap",
                          textDecoration: "line-through",
                        }}
                      >
                        {productData?.product?.prices?.originalPrice || productData?.product?.price}
                      </div>
                      <div
                        style={{ textWrap: "nowrap", marginLeft: "5px" }}
                        className="mont-bold"
                      >
                        ₹ {productData?.product?.prices?.price || productData?.product?.price}
                      </div>
                    </div>
                  ) : (
                    <div className="mont-bold">
                      ₹ {productData?.product?.prices?.price || productData?.product?.price}
                    </div>
                  )}
                </>
              )}
            </div>
            {/* {colors.length > 0 && <div className="colors">
            <div className="text">Color&nbsp;:</div>
            <div className="color-options">
              {
                colors.map((color, index) => (
                  <div
                    key={index}
                    className={`color-option ${colorOption === color ? "active" : ""}`}
                    onClick={() => handleColorClick(color)}
                  >
                    <div className="color-option-item" style={{ background: color }}></div>
                  </div>
                ))
              }
            </div>
          </div>} */}

            {/* for cluster products color option and on click of it naviagte to product id */}
            {/* {clusteredProducts.length > 0 && <div className="colors">
            <div className="text">Color&nbsp;:</div>
            <div className="color-options">
              {
                clusteredProducts.map((product, index) => (
                  <div
                    key={index}
                    className={`color-option ${colorOption === product?.code ? "active" : ""}`}
                    onClick={() => navigate(`/product/${storeType}/${product.product_id}`)}
                  >
                    <div className="color-option-item" style={{ background: product?.code }}></div>
                    <div className="color-option-name">{product.name}</div>
                  </div>
                ))
              }
            </div>
          </div>} */}

            {clusteredProducts.length > 1 &&
              productData?.product?.isAccessories !== true && (
                <div className="Clustered-products">
                  {clusteredProducts.map((product, index) => {
                    // image thumbnails
                    return (
                      <div
                        key={index}
                        className="Clustered-product pointer"
                        style={{
                          border:
                            productData?.product?._id === product.product_id ||
                            productData?.product?.product_id ===
                              product.product_id
                              ? "1px solid var(--color2)"
                              : "1px solid white",
                        }}
                        onClick={() =>
                          navigate(
                            `/product/${storeType}/${product.product_id}`
                          )
                        }
                      >
                        <div className="inner_img">
                          <img
                            src={product?.image?.[1]?.replace(
                              "upload/",
                              "upload/if_w_gt_200/w_200/if_end/"
                            )}
                            alt="Product"
                          />
                        </div>
                        <div className="Clustered-product-name">
                          {product?.name}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

            {options &&
              Object.keys(options).map((key) => (
                <div key={key} className="options-parent">
                  <div className="options-box">
                    <span className="info-grid-title mont-bold">{key}</span>
                    <div className="row-item">
                      {Object.keys(options[key]).map((key2) => (
                        <div
                          className="text"
                          key={key2}
                          onClick={() => {
                            handleOptionChange({
                              optionName: key,
                              optionValue: options[key][key2].optionValue,
                            });
                          }}
                          style={{
                            background: selectedOption.find(
                              (option) =>
                                option[key] === options[key][key2].optionValue
                            )
                              ? "var(--color2)"
                              : "white",
                            color: selectedOption.find(
                              (option) =>
                                option[key] === options[key][key2].optionValue
                            )
                              ? "white"
                              : "black",
                          }}
                        >
                          {options[key][key2].optionValue}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            {/* options end */}
            <div className="info-grid">
              <div className="row-item">
                <>
                  <div className="label">SKU&nbsp;:</div>
                  <div
                    className="text"
                    onClick={(e) => {
                      copyToClipboard(e, productData?.product?.sku);
                    }}
                  >
                    {productData?.product?.sku}
                  </div>
                </>
              </div>

              {attributes &&
                Object.keys(attributes).map((key) => (
                  <div key={key} className="attribute-parent">
                    <div className="attribute-box">
                      <span className="info-grid-title mont-bold">{key}</span>
                      {Object.keys(attributes[key]).map((key2) => (
                        // mark as selected when id value present in selectedOption with background color red

                        <div className="row-item" key={key2}>
                          <div className="label">
                            {attributes[key][key2].attributeName}
                          </div>
                          <div className="text">
                            {attributes[key][key2].attributeValue}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
            <div className="buttons">
              <div
                className="tryon-btn"
                id="jeweltry-button"
                ref={jeweltryBtnRef}
              >
                TRY ON
              </div>
              {storeType === "instore" && productData?.product && (
                <div
                  className={
                    isCombination
                      ? selectedVariant
                        ? "buy-now"
                        : "buy-now disabled"
                      : "buy-now"
                  }
                  onClick={() => {
                    if (productData?.product?.quantity < 1) {
                      toast.error("Out of stock");
                      return;
                    }
                    handleBuyNow(productData?.product);
                  }}
                >
                  {productData?.product?.quantity > 0
                    ? "BUY NOW "
                    : "Out Of Stock"}
                </div>
              )}
              {/* {storeType === "virtual" && <div onClick={()=>{setShowInquiryPopup(true)}} className="buy-now" >INQUIRE NOW</div>} */}
            </div>
            {storeType === "virtual" && (
              <div
                onClick={() => {
                  setShowInquiryPopup(true);
                }}
                className="instore-inquire"
              >
                INQUIRE
              </div>
            )}
          </div>
        </div>
      </div>
      <InquiryFlow
        data={productData}
        showInquiryPopup={showInquiryPopup}
        setShowInquiryPopup={setShowInquiryPopup}
        showInquiryTray={showInquiryTray}
        setShowInquiryTray={setShowInquiryTray}
        setShowProductPage={setShowProductPage}
        showInquiryReview={showInquiryReview}
        setShowInquiryReview={setShowInquiryReview}
      />
    </>
  );
}

export default ProductPage;
