import { Field, Formik, Form } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./CustomerReturnPage.scss";
import { Bounce, toast } from "react-toastify";
import {
  useExchangeCustomerOrderFromRetailerMutation,
  useReturnCustomerOrderFromRetailerMutation,
} from "../../features/order/cutomerOrderSlice";
import { getUser } from "../../home/Home";
import {
  useGetRetailerInfoQuery,
  useGetRetailerProductsQuery,
} from "../../features/product/productSlice";
function CustomerReturnPage() {
  //login
  const [user, setUser] = useState(getUser());
  const navigate = useNavigate();
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user]);
  //login end

  const location = useLocation();
  const submitButtonRef = useRef(null);
  const [
    returnCustomerOrderFromRetailer,
    { data: returnData, error: returnError, isLoading: returnLoading },
  ] = useReturnCustomerOrderFromRetailerMutation();

  const [
    exchangeCustomerOrderFromRetailer,
    { data: exchangeData, error: exchangeError, isLoading: exchangeLoading },
  ] = useExchangeCustomerOrderFromRetailerMutation();

  console.log(location.state);

  const {
    data: retailerData,
    isLoading: isLoadingRetailer,
    isSuccess: isSuccessRetailer,
    isError: isErrorRetailer,
    error: errorRetailer,
  } = useGetRetailerInfoQuery({ bearerToken: user });
  // console.log(retailerData)

  const [actionType, setActionType] = useState(
    location?.state?.actionType || ""
  );
  const [productToExchange, setProductToExchange] = useState(null);
  console.log(productToExchange);
  const [showInstoreProducts, setShowInstoreProducts] = useState(false);
  const pageRef = useRef(null);
  const instoreProductsRef = useRef(null);

  if (!location.state) {
    return null;
  }

  const order = location.state.order;
  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  const handleConfirmClick = (values) => {
    if (!productToExchange && actionType === "exchange") {
      toast.error("Please select a product to exchange");
      return;
    }
    if (values.reasons.length === 0) {
      if (!values.toggle) {
        toast.error("Please select a reason");
        return;
      }
      if (
        (!values.toggle && values.other === "") ||
        (values.toggle && values.other === "")
      ) {
        toast.error("Please select a reason");
        return;
      }
    }
    const returnReason =
      values.reasons.length > 0
        ? values.reasons.join(", ") + (values.toggle ? `, ${values.other}` : "")
        : values.other;

    if (actionType === "return") {
      const body = {
        orderId: order._id,
        cart: {
          product_id: order.cart[0].product_id,
          sku: order.cart[0].sku,
          returnReason: returnReason,
        },
      };
      returnCustomerOrderFromRetailer({ body: body, bearerToken: user }).then(
        (res) => {
          console.log(res);
          if (res.error) {
            toast.error("Error in returning order");
          } else {
            toast.success("Order returned successfully");
            navigate(-1);
          }
        }
      );
    }
    if (actionType === "exchange") {
      const exchangeBody = {
        orderId: order._id,
        paymentMethod: order.paymentMethod,
        shippingCost: order.shippingCost,
        subTotal: order.subTotal,
        status: "Placed",
        netreCashUsed: 0,
        total: order.total,
        customerId: order.user || "",
        user_info: order.user_info,
        reatilerId: retailerData.data._id,
        cart: [{ ...productToExchange, quantity: 1, orderStatus: "Placed" }],
        exchangedCart: {
          name: order.cart[0].name,
          product_id: order.cart[0].product_id,
          sku: order.cart[0].sku,
          exchangeReason: returnReason,
        },
      };
      console.log(exchangeBody);
      exchangeCustomerOrderFromRetailer({
        body: exchangeBody,
        bearerToken: user,
      }).then((res) => {
        console.log(res);
        if (res.error) {
          toast.error(res.error.data.message);
        } else {
          toast.success("Order exchanged successfully");
          navigate(-1);
        }
      });
    }
  };

  const scrollToTop = () => {
    pageRef.current?.scrollTo(0, 0);
    window.scrollTo(0, 0);
  };

  return (
    <div className="CustomerReturnPage" ref={pageRef}>
      <div
        onClick={() => {
          navigate(-1);
        }}
        className="add-frame-text"
      >
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
          >
            <path d="M400-107.692 27.692-480 400-852.308l42.539 42.539L112.769-480l329.77 329.769L400-107.692Z" />
          </svg>
        </span>
        <span>Orders</span>
      </div>

      <Formik
        initialValues={{
          picked: actionType,
        }}
        onSubmit={async (values) => {
          await new Promise((r) => setTimeout(r, 500));
          alert(JSON.stringify(values, null, 2));
        }}
      >
        {({ values }) => (
          <Form
            onChange={(e) => {
              setActionType(e.target.value);
            }}
          >
            <div
              role="group"
              aria-labelledby="my-radio-group"
              className="return-exchange-radios"
            >
              <label className="radio">
                <Field type="radio" name="picked" value="return" />
                Return Order
              </label>
              <label className="radio">
                <Field type="radio" name="picked" value="exchange" />
                Exchange Order
              </label>
              {/* <div>Picked: {values.picked}</div> */}
            </div>
          </Form>
        )}
      </Formik>

      <div className="box">
        <div className="container">
          <div className="return-reason">
            <div className="title">Select Reason</div>
            <Formik
              initialValues={{
                toggle: false,
                other: "",
                reasons: [],
              }}
              onSubmit={async (values) => {
                await sleep(500);
                // console.log(values)
                handleConfirmClick(values);
                // alert(JSON.stringify(values, null, 2));
              }}
            >
              {({ values }) => (
                <Form>
                  <div
                    role="group"
                    aria-labelledby="checkbox-group"
                    className="checkbox-grid"
                  >
                    <label className="checkbox-container">
                      <Field
                        type="checkbox"
                        name="reasons"
                        value="Damaged Product"
                      />
                      <span className="checkmark"></span>
                      <div>Damaged Product</div>
                    </label>
                    <label className="checkbox-container">
                      <Field
                        type="checkbox"
                        name="reasons"
                        value="Wrong Size"
                      />
                      <span className="checkmark"></span>
                      <div>Wrong Size</div>
                    </label>
                    <label className="checkbox-container">
                      <Field
                        type="checkbox"
                        name="reasons"
                        value="Wrong Product"
                      />
                      <span className="checkmark"></span>
                      <div>Wrong Product</div>
                    </label>
                    <label className="checkbox-container other">
                      <Field type="checkbox" name="toggle" />
                      <span className="checkmark"></span>
                      {/* <div>Other</div> */}
                      {/* if values.toggle is true then make a text field*/}
                      {
                        <Field
                          type="textarea"
                          name="other"
                          disabled={!values.toggle}
                          placeholder="Specify Other"
                          style={{
                            opacity: "unset",
                            height: "unset",
                            width: "unset",
                            margin: "0",
                            paddingLeft: "0",
                          }}
                        />
                      }
                    </label>
                    <div></div>
                  </div>

                  <div
                    className="confirm-box"
                    style={
                      actionType == "exchange"
                        ? {
                            visibility: "hidden",
                            position: "absolute",
                            zIndex: -1,
                          }
                        : {}
                    }
                  >
                    <button
                      className="confirm"
                      type="submit"
                      ref={submitButtonRef}
                    >
                      CONFIRM
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          {actionType === "exchange" && (
            <>
              <hr />
              <div className="exchange">
                <div className="selection-grid">
                  <div className="old">
                    <div className="img">
                      <img
                        src={order.cart[0].images[0].replace(
                          "upload/",
                          "upload/if_w_gt_400/w_400/if_end/"
                        )}
                        alt="product"
                      />
                    </div>
                    <div className="details">
                      <div className="name">{order.cart[0].name}</div>
                      <div className="sku">SKU: {order.cart[0].sku}</div>
                    </div>
                  </div>
                  <div className="arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="#5f6368"
                    >
                      <path d="M683.15-460H200v-40h483.15L451.46-731.69 480-760l280 280-280 280-28.54-28.31L683.15-460Z" />
                    </svg>
                  </div>
                  <div className="new">
                    {!productToExchange && (
                      <div
                        className="img"
                        onClick={() => {
                          setShowInstoreProducts(true);
                          instoreProductsRef.current?.scrollIntoView({
                            behavior: "smooth",
                          });
                        }}
                      >
                        {/* <img src={order.cart[0].images[0]} alt="product" /> */}
                        <div className="add">
                          <div className="plus">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24px"
                              viewBox="0 -960 960 960"
                              width="24px"
                              fill="#5f6368"
                            >
                              <path d="M450-450H220v-60h230v-230h60v230h230v60H510v230h-60v-230Z" />
                            </svg>
                          </div>
                          <div className="text">Add Frame</div>
                        </div>
                      </div>
                    )}
                    {productToExchange && (
                      <>
                        <div className="img">
                          {productToExchange?.images && (
                            <img
                              src={productToExchange?.images[0].replace(
                                "upload/",
                                "upload/if_w_gt_400/w_400/if_end/"
                              )}
                              alt="product"
                            />
                          )}
                        </div>
                        <div className="details">
                          <div className="name">{productToExchange.name}</div>
                          <div className="sku-text">
                            SKU: {productToExchange.sku}
                          </div>
                        </div>
                      </>
                    )}

                    {/* <div className="sku"><input type="text" name="sku"  placeholder="SKU"/>
                    <div className="add-sku">Add</div>
                  </div> */}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="summary">
          {actionType === "return" && (
            <div className="summary-container">
              <div className="title">Order Summary</div>
              <div className="img">
                <img
                  src={order.cart[0].images[0].replace(
                    "upload/",
                    "upload/if_w_gt_400/w_400/if_end/"
                  )}
                  alt="product"
                />
              </div>
              <div className="details">
                <div className="name">{order.cart[0].name}</div>
                <div className="sku">SKU: {order.cart[0].sku}</div>
              </div>
              <div className="price-breakdown">
                <div className="label mont-bold">Price Breakdown</div>
                <div className="prices">
                  <div className="att-label">Frame Price</div>
                  <div className="price">&#8377;{order.subTotal}</div>
                </div>
                <div className="prices">
                  <div className="att-label">Discount</div>
                  <div className="price">&#8377;{order.discount}</div>
                </div>
                <div className="prices total">
                  <div className="att-label">Total Price</div>
                  <div className="price">&#8377;{order.total}</div>
                </div>
                <div className="text2 payment">
                  Mode of payment: {order.paymentMethod}
                </div>
                <div className="text2">Amount Paid: &#8377;{order.total}</div>
                <div className="text2">Amount Pending: &#8377;0</div>
              </div>
            </div>
          )}
          {actionType === "exchange" && (
            <div className="summary-container">
              <div className="title">New Order Summary</div>
              <div className="img">
                {productToExchange?.images && (
                  <img
                    src={productToExchange?.images[0].replace(
                      "upload/",
                      "upload/if_w_gt_400/w_400/if_end/"
                    )}
                    alt="product"
                  />
                )}
              </div>
              <div className="details">
                {productToExchange?.name && (
                  <div className="name">{productToExchange?.name}</div>
                )}
                {productToExchange?.sku && (
                  <div className="sku">SKU: {productToExchange?.sku}</div>
                )}
              </div>
              <div className="price-breakdown">
                <div className="label mont-bold">Price Breakdown</div>
                <div className="prices">
                  <div className="att-label">Frame Price</div>
                  <div className="price">&#8377;{order.subTotal}</div>
                </div>
                <div className="prices">
                  <div className="att-label">New Frame Price</div>
                  <div className="price">&#8377;{productToExchange?.price}</div>
                </div>
                {/* <div className="prices">
                <div className="att-label">Discount</div>
                <div className="price">&#8377;{order.discount}</div>
              </div>
              <div className="prices total">
                <div className="att-label">Total Price</div>
                <div className="price">&#8377;{order.total}</div>
              </div>
              <div className="text2 payment">Mode of payment: {order.paymentMethod}</div>
              <div className="text2">Amount Paid: &#8377;{order.total}</div>
              <div className="text2">Amount Pending: &#8377;0</div> */}
              </div>
            </div>
          )}
        </div>
      </div>

      {actionType === "exchange" && (
        <>
          <div className="confirm-box exchange-confirm">
            <button
              className="confirm"
              onClick={() => {
                submitButtonRef.current?.click();
              }}
            >
              Exchange
            </button>
          </div>
          <div className="instore-products" ref={instoreProductsRef}>
            {showInstoreProducts && (
              <InStoreProducts
                setProductToExchange={setProductToExchange}
                productToExchange={productToExchange}
                scrollToTop={scrollToTop}
              />
            )}
          </div>
        </>
      )}

      <div className="confirm-box-mobile">
        <button
          className="confirm"
          onClick={() => {
            submitButtonRef.current?.click();
          }}
        >
          CONFIRM
        </button>
      </div>
    </div>
  );
}

export default CustomerReturnPage;

function InStoreProducts(props) {
  //login
  const [user, setUser] = useState(getUser());
  const navigate = useNavigate();
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user]);
  //login end

  const {
    data: productsData,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetRetailerProductsQuery({ bearerToken: user });
  if (isError && error.status === 401) {
    navigate("/login");
  }

  return (
    <div className="cards">
      {isLoading && <div>Loading..</div>}
      {productsData && productsData.data.inventory.length === 0 && (
        <div>No products available </div>
      )}
      {productsData &&
        productsData.data.inventory.map((item) => (
          <div className="card" key={item._id}>
            <div className="top">
              {props.productToExchange?._id === item._id && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="#5f6368"
                >
                  <path d="m422.46-323.69 255.85-255.85L650-607.85 422.46-380.31l-114-114L280.15-466l142.31 142.31ZM480.13-120q-74.67 0-140.41-28.34-65.73-28.34-114.36-76.92-48.63-48.58-76.99-114.26Q120-405.19 120-479.87q0-74.67 28.34-140.41 28.34-65.73 76.92-114.36 48.58-48.63 114.26-76.99Q405.19-840 479.87-840q74.67 0 140.41 28.34 65.73 28.34 114.36 76.92 48.63 48.58 76.99 114.26Q840-554.81 840-480.13q0 74.67-28.34 140.41-28.34 65.73-76.92 114.36-48.58 48.63-114.26 76.99Q554.81-120 480.13-120Zm-.13-40q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                </svg>
              )}
              {item?.quantity > 0 &&
                props.productToExchange?._id != item._id && (
                  <svg
                    onClick={() => {
                      props.setProductToExchange(item);
                      props.scrollToTop();
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#5f6368"
                  >
                    <path d="M460-300h40v-160h160v-40H500v-160h-40v160H300v40h160v160Zm20.13 180q-74.67 0-140.41-28.34-65.73-28.34-114.36-76.92-48.63-48.58-76.99-114.26Q120-405.19 120-479.87q0-74.67 28.34-140.41 28.34-65.73 76.92-114.36 48.58-48.63 114.26-76.99Q405.19-840 479.87-840q74.67 0 140.41 28.34 65.73 28.34 114.36 76.92 48.63 48.58 76.99 114.26Q840-554.81 840-480.13q0 74.67-28.34 140.41-28.34 65.73-76.92 114.36-48.58 48.63-114.26 76.99Q554.81-120 480.13-120Zm-.13-40q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                  </svg>
                )}
            </div>
            <div className="middle">
              <img
                src={
                  item.images[0]
                    ? item.images[0].replace(
                        "upload/",
                        "upload/if_w_gt_400/w_400/if_end/"
                      )
                    : "/Netre-logo.svg"
                }
                className="product-image"
                alt="Product"
                title="Product"
                loading="lazy"
              />
            </div>
            <div className="bottom">
              {item.title && (
                <div
                  className={
                    props.storeType === "Virtual"
                      ? "title qty-present"
                      : "title"
                  }
                >
                  {item.title?.en}
                </div>
              )}
              {item.name && (
                <div
                  className={
                    props.storeType === "Virtual"
                      ? "title qty-present"
                      : "title"
                  }
                >
                  {item?.name}
                </div>
              )}
              <div className="sku show qty-present">SKU : {item.sku}</div>
            </div>
            {Number(item?.quantity) < 1 && (
              <div className="sold-out">
                <div className="div">SOLD OUT</div>
              </div>
            )}
          </div>
        ))}
    </div>
  );
}
