import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetProductsMutationMutation,
  useGetProductsQuery,
  useGetRetailerProductsQuery,
} from "../../features/product/productSlice";
import { getUser } from "../../home/Home";
import ProductCard from "../productCard/ProductCard";

import "./VirtualTryon.scss";
function VirtualTryon({}) {
  //login
  const [user, setUser] = useState(getUser());
  const navigate = useNavigate();
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user]);
  //login end

  const params = useParams();
  const [storeType, setStoreType] = useState(
    params?.toggleValue === "in-store" ? "In Store" : "Virtual"
  );
  useEffect(() => {
    setStoreType(params?.toggleValue === "in-store" ? "In Store" : "Virtual");
  }, [params]);

  return (
    <div className="virtual-tryon">
      <div className="container">
        <div className="store-types">
          <div
            className={
              storeType === "Virtual" ? "store-type active" : "store-type"
            }
            onClick={() => {
              navigate("/inventory/virtual");
            }}
          >
            Virtual
          </div>
          <div
            className={
              storeType === "In Store" ? "store-type active" : "store-type"
            }
            onClick={() => {
              navigate("/inventory/in-store");
            }}
          >
            In Store
          </div>
          <div className="border"></div>
          {/* {storeType === "Virtual" && props.route === "/b2b/virtual-tryon" && <div className="inquiry-tray-btn">INQUIRY TRAY</div>} */}
          {/* {storeType === "Virtual" && <div className="inquiry-tray-btn">INQUIRY TRAY</div>} */}
        </div>
      </div>
      {storeType === "Virtual" && <VirtualProducts storeType={"Virtual"} />}
      {storeType === "In Store" && <InStoreProducts storeType={"In Store"} />}
    </div>
  );
}

export default VirtualTryon;

export function VirtualProducts(props) {
  const [getProductsMutation] = useGetProductsMutationMutation();

  const [page, setPage] = useState(1);
  const [allLoaded, setAllLoaded] = useState(false); // To track if all items are loaded
  const [items, setItems] = useState([]); // Holds the grid items
  const [loading, setLoading] = useState(false); // Loading state
  const limit = 10; // Limit per page
  const observer = useRef(); // To track the 10th item for intersection

  const fetchData = async (page, limit) => {
    const data = await getProductsMutation({
      page: page,
      limit: limit,
    }).then((res) => {
      if (res.data) {
        if (res.data.products.length === 0) {
          setAllLoaded(true);
        }
        return res.data.products;
      }
      if (res.error) {
        console.log(res.error);
      }
    });
    return data;
  };

  // Load data on page change
  const loadItems = async () => {
    setLoading(true);
    const newItems = await fetchData(page, limit); // Fetch new items based on page
    // setItems((prev) => [...prev, ...newItems]); // Append new items to the list
    // check if  the prev items are present or not only add those which are not present
    setItems((prev) => {
      // Create a Map where the key is the item._id and the value is the item itself
      const map = new Map();

      // Combine prev and newItems arrays
      const combinedItems = [...prev, ...newItems];

      // Iterate over the combined array and set each item in the Map using its _id as the key
      combinedItems.forEach((item) => map.set(item._id, item));

      // Return an array of the values from the Map (which will be unique by _id)
      return Array.from(map.values());
    });
    setLoading(false);
  };

  // Intersection observer callback
  const lastItemRef = useCallback(
    (node) => {
      if (loading) return; // If loading, do nothing

      // Disconnect previous observer
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          // When the 10th item (or last item in the list) is in view, increment page number
          setPage((prevPage) => prevPage + 1);
        }
      });

      if (node) observer.current.observe(node); // Observe the new node
    },
    [loading]
  );

  //use folowing function to reset and empty the items
  const resetAll = () => {
    if (observer.current) observer.current.disconnect();
    observer.current = null;
    setItems([]);
    setAllLoaded(false);
    setPage(1);
    loadItems();
  };

  useEffect(() => {
    if (!allLoaded) {
      loadItems();
    }
  }, [page]);
  return (
    <div className="cards">
      {loading && <div>Loading..</div>}
      {!items.length > 0 && <div>No Products Available</div>}
      {items &&
        items.map((item, index) => {
          if (index === items.length - 1) {
            return (
              item?.isAccessories !== true && (
                <div ref={lastItemRef} key={index}>
                  <ProductCard
                    key={item._id}
                    product={item}
                    route={"/virtual-tryon"}
                    storeType={"Virtual"}
                    {...props}
                  />
                </div>
              )
            );
          } else {
            return (
              item?.isAccessories !== true && (
                <div key={index}>
                  <ProductCard
                    key={item._id}
                    product={item}
                    route={"/virtual-tryon"}
                    storeType={"Virtual"}
                    {...props}
                  />
                </div>
              )
            );
          }
        })}
    </div>
  );
}

export function InStoreProducts(props) {
  //login
  const [user, setUser] = useState(getUser());
  const navigate = useNavigate();
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user]);
  //login end

  const {
    data: productsData,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetRetailerProductsQuery({ bearerToken: user });
  if (isError && error.status === 401) {
    navigate("/login");
  }

  return (
    <div className="cards">
      {isLoading && <div>Loading..</div>}
      {productsData && productsData.data.inventory.length === 0 && (
        <div>No products available </div>
      )}
      {productsData &&
        [...productsData.data.inventory]
          .reverse()
          .map(
            (item) =>
              item?.isAccessories !== true && (
                <ProductCard
                  key={item._id}
                  product={item}
                  storeType={"In Store"}
                  {...props}
                />
              )
          )}
    </div>
  );
}
