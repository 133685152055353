import React, { useState } from "react";
import { useFormik } from "formik";
import "./UploadBills.scss";
import DOWN_ARROW_IC from "../../assets/icons/down_arror_ic.svg";
import { DELETE_IC, ORDER_IC, UPLOAD_BILL_IC } from "../../assets/index";
import { useAddCustomerInvoiceMutation } from "../../features/order/cutomerOrderSlice";
import { toast } from "react-toastify";
import { getUser } from "../../home/Home";

function UploadBills() {
  const [user, setUser] = useState(getUser());
  const [openDetails, setOpenDetails] = useState(-1);
  const [addCustomerInvoice] = useAddCustomerInvoiceMutation();

  const validate = (values) => {
    const errors = { forms: [] };

    values.forms.forEach((form, index) => {
      const formErrors = {};

      // SKU Validation
      if (!form.sku) {
        formErrors.sku = "SKU is required";
      }
      if (!form.quantity) {
        formErrors.quantity = "Quantity is required";
      }

      // Amount Validation
      if (!form.amount) {
        formErrors.amount = "Amount is required";
      }

      // Customer Name Validation
      if (!form.customerName) {
        formErrors.customerName = "Customer name is required";
      }

      // Customer Number Validation (must be exactly 10 digits)
      if (!form.customerNumber) {
        formErrors.customerNumber = "Customer number is required";
      } else if (!/^\d{10}$/.test(form.customerNumber)) {
        formErrors.customerNumber = "Customer number must be exactly 10 digits";
      }

      // Bill PDF Validation (only PDF files allowed)
      if (!form.billPdf) {
        formErrors.billPdf = "Bill PDF is required";
      } else if (form.billPdf && form.billPdf.type !== "application/pdf") {
        formErrors.billPdf = "Only PDF files are allowed";
      }

      errors.forms[index] = formErrors;
    });

    return errors.forms.some((formErrors) => Object.keys(formErrors).length)
      ? errors
      : {};
  };

  const formik = useFormik({
    initialValues: {
      forms: [
        {
          sku: "",
          quantity: "",
          amount: "",
          customerName: "",
          customerNumber: "",
          billPdf: null,
        },
      ],
    },
    validate,
    onSubmit: (values) => {
      const formdata = new FormData();

      // Array to hold the invoices data in JSON format
      const invoices = [];

      // Loop through each form entry
      values.forms.forEach((form, index) => {
        // Create a dynamic file key (e.g., invoiceFiles_1_invoice_001)
        const fileKey = `invoiceFiles_${index + 1}_invoice_${(index + 1)
          .toString()
          .padStart(3, "0")}`;

        // Append the file with dynamic key
        if (form.billPdf) {
          formdata.append(fileKey, form.billPdf, "file");
        }

        // Build invoice object with file field reference
        const invoice = {
          sku: form.sku,
          quantity: form.amount, // Assuming amount is the quantity here
          bill_amount: form.amount, // Assuming amount is bill amount
          customer_name: form.customerName,
          customer_number: form.customerNumber,
          fileFieldName: fileKey,
        };

        // Push the invoice data to the invoices array
        invoices.push(invoice);
      });

      // Append the invoices as a JSON string
      formdata.append("invoices", JSON.stringify(invoices));

      // Debugging: log form data entries
      for (let [key, value] of formdata.entries()) {
        console.log(key, value);
      }

      // API call with FormData
      addCustomerInvoice({
        body: formdata,
        bearerToken: user,
      }).then((res) => {
        if (res?.data) {
          toast.success(`Invoice added successfully.`);
          formik.resetForm();
          const newForm = {
            sku: "",
            quantity: "",
            amount: "",
            customerName: "",
            customerNumber: "",
            billPdf: null,
          };
          formik.setFieldValue("forms", [newForm]);
        } else {
          toast.error(`${res?.error?.data?.message}`);
        }
      });
    },
  });

  const handleAddForm = () => {
    const newForm = {
      sku: "",
      quantity: "",
      amount: "",
      customerName: "",
      customerNumber: "",
      billPdf: null,
    };
    formik.setFieldValue("forms", [...formik.values.forms, newForm]);
  };

  const handleInputChange = (index, event) => {
    const { name, value, files } = event.target;
    const newForms = [...formik.values.forms];
    newForms[index][name] = files ? files[0] : value;
    formik.setFieldValue("forms", newForms);
  };

  // =========== DELETE BILL FORM =============

  const deleteBillFormHandle = (id) => {
    if (formik.values.forms?.length === 1) {
      return toast.error("One bill is required!");
    } else {
      const updatedForms = formik.values.forms.filter((_, i) => i !== id);
      formik.setFieldValue("forms", updatedForms);
    }
  };

  return (
    <div className="UploadBills">
      <h1>Upload Bills</h1>
      <h3 className="text-2">To order/replace frames upload bills</h3>
      <div className="container">
        <div className="left">
          {/* Render UploadForm component for each form */}
          {formik.values.forms.map((item, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span>Bill: {index + 1}</span>
                <img
                  src={DELETE_IC}
                  onClick={() => deleteBillFormHandle(index)}
                  alt=""
                  style={{ width: "20px", cursor: "pointer" }}
                />
              </div>
              <UploadForm
                key={index}
                index={index}
                data={item}
                handleInputChange={handleInputChange}
                errors={formik.errors.forms?.[index]}
                touched={formik.touched.forms?.[index]}
              />
            </div>
          ))}
          {/* Add more form btn */}
          <div className="add-more">
            <button className="btn" onClick={handleAddForm}>
              Add More
            </button>
          </div>
        </div>

        <div className="right">
          <h2>Bill Summary</h2>
          {/* Forms data display */}
          {formik.values.forms.map((item, index) => (
            <div className="accordian" key={index}>
              <div
                onClick={() =>
                  setOpenDetails(openDetails !== index ? index : -1)
                }
                className="accordian-header"
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img src={ORDER_IC} alt="" style={{ width: "20px" }} />
                  <h3 style={{ marginLeft: "10px" }}>Bill {index + 1}</h3>
                </div>
                <div className="accordian-icon">
                  <img
                    src={DOWN_ARROW_IC}
                    alt=""
                    className={` ${
                      openDetails === index ? "active_up_down_ic" : "up_down_ic"
                    }`}
                  />
                </div>
              </div>
              {openDetails === index && (
                <div className="accordian-content">
                  <p>SKU: {item.sku}</p>
                  <p>Quantity: {item.quantity}</p>
                  <p>Bill Amount: {item.amount}</p>
                  <p>Customer Name: {item.customerName}</p>
                  <p>Customer Number: {item.customerNumber}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <button
          type="submit"
          onClick={formik.handleSubmit}
          style={{
            backgroundColor: "#A9772B",
            color: "white",
            fontSize: "16px",
            padding: "8px 20px",
            outline: "none",
            border: "none",
            cursor: "pointer",
            marginTop: "20px",
          }}
        >
          CONFIRM
        </button>
      </div>
    </div>
  );
}

export default UploadBills;

const UploadForm = ({ index, data, handleInputChange, errors, touched }) => {
  return (
    <form className="upload-form">
      {/* SKU Input */}
      <input
        type="text"
        placeholder="SKU"
        name="sku"
        value={data.sku}
        onChange={(e) => handleInputChange(index, e)}
      />
      {errors?.sku && touched && (
        <span style={{ color: "red", fontSize: "14px" }}>{errors.sku}</span>
      )}

      {/* Quantity Input */}
      <input
        type="text"
        placeholder="quantity"
        name="quantity"
        value={data.quantity}
        onChange={(e) => handleInputChange(index, e)}
      />
      {errors?.quantity && touched && (
        <span style={{ color: "red", fontSize: "14px" }}>
          {errors.quantity}
        </span>
      )}

      {/* Amount Input */}
      <input
        type="text"
        placeholder="Amount"
        name="amount"
        value={data.amount}
        onChange={(e) => handleInputChange(index, e)}
      />
      {errors?.amount && touched && (
        <span style={{ color: "red", fontSize: "14px" }}>{errors.amount}</span>
      )}

      {/* Customer Name Input */}
      <input
        type="text"
        placeholder="Customer Name"
        name="customerName"
        value={data.customerName}
        onChange={(e) => handleInputChange(index, e)}
      />
      {errors?.customerName && touched && (
        <span style={{ color: "red", fontSize: "14px" }}>
          {errors.customerName}
        </span>
      )}

      {/* Customer Number Input */}
      <input
        type="text"
        placeholder="Customer Number"
        name="customerNumber"
        value={data.customerNumber}
        onChange={(e) => handleInputChange(index, e)}
      />
      {errors?.customerNumber && touched && (
        <span style={{ color: "red", fontSize: "14px" }}>
          {errors.customerNumber}
        </span>
      )}

      {/* Bill PDF Input */}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <label
          className="pdf-upload"
          htmlFor={`file_upload_${index}`}
          style={{ cursor: "pointer" }}
        >
          <input
            id={`file_upload_${index}`}
            type="file"
            style={{ display: "none" }}
            name="billPdf"
            onChange={(e) => handleInputChange(index, e)}
          />
          {data?.billPdf === null ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src={UPLOAD_BILL_IC} alt="" />
              <span style={{ marginTop: "5px" }}>Upload Bill</span>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                padding: "0 10px",
                overflow: "hidden",
              }}
            >
              <img src={ORDER_IC} alt="" />
              <span style={{ marginTop: "5px", textAlign:"center" }}>{data.billPdf?.name}</span>
            </div>
          )}
        </label>
        {errors?.billPdf && touched && (
          <span style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>
            {errors.billPdf}
          </span>
        )}
      </div>
    </form>
  );
};

// import React, { useState } from 'react'
// import './UploadBills.scss'
// function UploadBills() {
//   // on click of add more btn, add more form and save the data for every added form into an array
//   const [form, setForm] = useState([
//     {
//       sku: '',
//       amount: '',
//       customerName: '',
//       customerNumber: '',
//       billPdf: ''
//     }
//   ])
//   console.log(form)

//   const handleAddMore = () => {
//     setForm([...form,
//       {
//         sku: '',
//         amount: '',
//         customerName: '',
//         customerNumber: '',
//         billPdf: ''
//       }])
//   }
//   return (
//     <div className='UploadBills'>
//       <h1>Upload Bills</h1>
//       <h3 className='text-2'>To order/replace frames upload bills</h3>
//       <div className="container">
//         <div className="left">
//         {/* add more form btn */}
//           <div className="add-more">
//             <button className='btn' onClick={handleAddMore}>Add More</button>
//           </div>
//           {/* render UploadForm component */}
//           {form.map((item, index) => (
//             <UploadForm key={index} handleAddMore={handleAddMore}/>
//           ))}
//         </div>
//         <div className="right">
//           <h2>Bill Summary</h2>
//           {/* forms data display */}
//           {form.map((item, index) => (
//             <div className="accordian" key={index}>
//               <div className="accordian-header">
//                 <h3>Bill {index+1}</h3>
//                 <div className="accordian-icon">+</div>
//               </div>
//               <div className="accordian-content">
//                 <p>SKU: {item.sku}</p>
//                 <p>Bill Amount: {item.amount}</p>
//                 <p>Customer Name: {item.customerName}</p>
//                 <p>Customer Number: {item.customerNumber}</p>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   )
// }

// export default UploadBills

// const UploadForm = (props) => {
//   // form fields save to state and pass the data to parent component
//   const [sku, setSku] = useState('')
//   const [amount, setAmount] = useState('')
//   const [customerName, setCustomerName] = useState('')
//   const [customerNumber, setCustomerNumber] = useState('')
//   const [billPdf, setBillPdf] = useState('')

//   const handleSku = (e) => {
//     setSku(e.target.value)
//     handleFormSubmit()
//   }
//   const handleAmount = (e) => {
//     setAmount(e.target.value)
//     handleFormSubmit()
//   }
//   const handleCustomerName = (e) => {
//     setCustomerName(e.target.value)
//     handleFormSubmit()
//   }
//   const handleCustomerNumber = (e) => {
//     setCustomerNumber(e.target.value)
//     handleFormSubmit()
//   }
//   const handleBillPdf = (e) => {
//     setBillPdf(e.target.value)
//     handleFormSubmit()
//   }

//   const handleFormSubmit = () => {
//     const data = {
//       sku: sku,
//       amount: amount,
//       customerName: customerName,
//       customerNumber: customerNumber,
//       billPdf: billPdf
//     }
//     console.log(data)
//   }

//   return (
//     <form className="upload-form" onSubmit={()=> {return false}} data={props.data}>
//       <input type="text" placeholder="SKU" onChange={handleSku} />
//       <input type="text" placeholder="Amount" onChange={handleAmount}/>
//       <input type="text" placeholder="Customer Name" onChange={handleCustomerName}/>
//       <input type="text" placeholder="Customer Number" onChange={handleCustomerNumber}/>
//       {/* <input type="file" placeholder="Bill PDF" onChange={handleBillPdf}/> */}
//       <div className="pdf-upload">Upload Bill</div>
//     </form>
//   )
// }
